jQuery(document).ready(function($) {
	/* Mobile menu */
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-right"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	/* FitVids */
	if ($("body").innerWidth() <= 767 ) {
		$(".inside-page-container").fitVids({ ignore: "nofit" });
		$(".homeboxes").fitVids({ ignore: "nofit" });
	}

	/* Accessible menu */
	$(".nav-drop-wrap.hasChildren .container-wrapper").hover(
		function() {
			$(this).prev().addClass('hover-dropdown');
		},
		function() {
			$(this).prev().removeClass('hover-dropdown');
		}
	);

	if (navigator.userAgent.match(/iPad/i) != null) {
		$(".hasChildren > .nav-drop-toggle-link").click(function(event) {
			if ( !$(this).parents(".hasChildren").hasClass("open") ) {
				$(".nav-drop-wrap").removeClass("open");
				event.preventDefault();
				$(this).parent(".hasChildren").addClass("open");
			}
		});
	}
	else {
		$(".nav-drop-wrap").hover(function() {
			$(this).addClass("open");
		}, function() {
			$(this).removeClass("open");
		});
	}
	$(".accesible-navigation-menu").accessibleMenu();


	/* Alert Region */
	$( "#closeAlertButton" ).click(function() {
		setCookie("alertRegion", "isShown");
	});

	function setCookie(cname, cvalue) {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}

	if ( document.cookie.indexOf("alertRegion=") == -1 ) {
		$(".alert-bar").show();
	}


	/* Webflow init */
	Webflow.require('ix').init([
		{slug: "fade-in-bottom-page-loads",name: "Fade in bottom (page loads)",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "load",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-left-scroll-in",name: "Fade in left (scroll in)",value: {style: {opacity: 0,x: "-50px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-right-scroll-in",name: "Fade in right (scroll in)",value: {style: {opacity: 0,x: "50px",y: "0px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-top-scroll-in",name: "Fade in top (scroll in)",value: {style: {opacity: 0,x: "0px",y: "-50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "fade-in-bottom-scroll-in",name: "Fade in bottom (scroll in)",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0ms, opacity 1000ms ease 0ms",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "bounce-in-scroll-in",name: "Bounce in (scroll in)",value: {style: {opacity: 0,scaleX: 0.6000000000000006,scaleY: 0.6000000000000006,scaleZ: 1},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 600ms ease 0ms, opacity 600ms ease 0ms",scaleX: 1.08,scaleY: 1.08,scaleZ: 1},{transition: "transform 150ms ease-out-cubic 0ms",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "scale-on-scroll",name: "Scale on Scroll",value: {style: {opacity: 0,scaleX: 0.01,scaleY: 0.01,scaleZ: 1},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 600ms ease 0ms, opacity 600ms ease 0ms",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}},
		{slug: "close-alert-box",name: "Close Alert Box",value: {style: {},triggers: [{type: "click",selector: ".alert-bar",stepsA: [{display: "none"}],stepsB: []}]}},
		{slug: "search-dropdown",name: "Search Dropdown",value: {style: {},triggers: [{type: "click",selector: ".header-search-container",stepsA: [{display: "block",height: "auto",transition: "height 350ms ease 0"}],stepsB: [{display: "none",height: "0px",transition: "height 350ms ease 0"}]}]}},
		{slug: "news-cb1",name: "News Cb1",value: {style: {opacity: 0.7500000000000001,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 900ms ease 0, opacity 900ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "news-cb2",name: "News Cb2",value: {style: {opacity: 0.7500000000000001,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1100ms ease 0, opacity 1100ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "news-cb3",name: "News Cb3",value: {style: {opacity: 0.7000000000000001,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1300ms ease 0, opacity 1300ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "new-interaction",name: "New Interaction",value: {style: {},triggers: []}},
		{slug: "quick-link-appear",name: "Quick Link Appear",value: {style: {opacity: 0,x: "0px",y: "50px",z: "0px"},triggers: [{type: "scroll",stepsA: [{opacity: 1,transition: "transform 1000ms ease 0, opacity 1000ms ease 0",x: "0px",y: "0px",z: "0px"}],stepsB: []}]}},
		{slug: "slide-overlay-appear",name: "Slide Overlay Appear",value: {style: {opacity: 0,scaleX: 0.8,scaleY: 0.8,scaleZ: 1},triggers: [{type: "slider",stepsA: [{opacity: 1,transition: "transform 900ms ease 0, opacity 800ms ease 0",scaleX: 1,scaleY: 1,scaleZ: 1}],stepsB: []}]}}
	]);
});

! function(o, c) {
	var n = c.documentElement,
		t = " w-mod-";
	n.className += t + "js", ("ontouchstart" in o || o.DocumentTouch && c instanceof DocumentTouch) && (n.className += t + "touch")
}(window, document);
